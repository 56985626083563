import { Routes } from '@angular/router';
import {BsCoreAuthenticatedGuard} from "@boekscout/bs-core";

export const appRoutes: Routes = [
    {
        loadComponent: () => import('./components/app-home.component').then(mod => mod.AppHomeComponent),
        path: '',
        canActivate: [
            BsCoreAuthenticatedGuard,
        ],
    },
    {
        loadComponent: () => import('./components/app-impersonate.component').then(mod => mod.AppImpersonateComponent),
        path: 'impersonate/:token/:expire/:account',
    },
    {
        loadChildren: () => import('../administration/author-administration.routes').then(m => m.authorAdministrationRoutes),
        path: 'administration',
        canActivate: [
            BsCoreAuthenticatedGuard,
        ],
    },
    {
        loadChildren: () => import('../author-blog/author-blog.routes').then(m => m.authorBlogRoutes),
        path: 'author-blog',
        canActivate: [
            BsCoreAuthenticatedGuard,
        ],
    },
    {
        loadChildren: () => import('../manuscript/author-manuscript.routes').then(m => m.authorManuscriptRoutes),
        path: 'manuscript',
        canActivate: [
            BsCoreAuthenticatedGuard,
        ],
    },
    {
        loadChildren: () => import('../marketing/author-marketing.routes').then(m => m.authorMarketingRoutes),
        path: 'marketing',
        canActivate: [
            BsCoreAuthenticatedGuard,
        ],
    },
    {
        loadChildren: () => import('../production/author-production.routes').then(m => m.authorProductionRoutes),
        path: 'production',
        canActivate: [
            BsCoreAuthenticatedGuard,
        ],
    },
    {
        loadChildren: () => import('../me/author-me.routes').then(m => m.authorMeRoutes),
        path: 'me',
    },
];
