export const environment = {
    authCookie: true,
    production: true,
    development: true,
    showReference: 3,
    services: {
        'bs-administration': {
            uri: 'https://administration.service.boekscout.dev/',
            token: true,
        },
        'bs-author-blog': {
            uri: 'https://author-blog.service.boekscout.dev/',
            token: true,
        },
        'bs-data': {
            uri: 'https://data.service.boekscout.dev/',
            token: true,
        },
        'bs-storage': {
            uri: 'https://storage.service.boekscout.dev/',
            token: true,
        },
        'bs-manuscript': {
            uri: 'https://manuscript.service.boekscout.dev/',
            token: true,
        },
        'bs-production': {
            uri: 'https://production.service.boekscout.dev/',
            token: true,
        },
        'bs-marketing': {
            uri: 'https://marketing.service.boekscout.dev/',
            token: true,
        },
    },
};
