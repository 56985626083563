import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BsCoreErrorService} from '@boekscout/bs-core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private errorService: BsCoreErrorService) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next
            .handle(req)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        this.errorService.set({code: 'authentication', context: null});
                    } else if (err.status === 403) {
                        this.errorService.set({code: 'authorization', context: null});
                    } else if (err.status === 405) {
                        this.errorService.set({code: 'methodNotAllowed', context: null});
                    } else if (err.status === 429) {
                        this.errorService.set({code: 'tooManyRequests', context: null});
                    } else if (err.status >= 500 && err.status <= 599) {
                        this.errorService.set({code: 'server', context: null});
                    }

                    return throwError(() => err);
                }),
            );
    }
}
